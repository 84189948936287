import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useUserPreferences } from '@Contexts/UserPreferencesContext/UserPreferencesContext';
import { useQueryParams } from '@Core/helpers/url';
import { getSearchSelectionFromUrl } from '@Stores/getSearchSelectionFromUrl';
import { createSearchSelectionStore, SearchSelectionStore } from '@Stores/SearchSelectionStore';

/**
 * Returns initial search selection from url params or cookie value.
 * If url params are set, then it updates the cookie value.
 */
export const useInitialSearchSelection = (forceMergeState: boolean = false) => {
  const initialSearchSelection = useRef<Partial<SearchSelectionStore> | null>(null);
  const { savedSearchSelection } = useUserPreferences();
  const { search } = useLocation();
  const { mergeState } = useQueryParams();

  if (!initialSearchSelection.current) {
    const stateFromUrl = getSearchSelectionFromUrl(search);
    const stateFromCookie = getSearchSelectionFromUrl(savedSearchSelection.get() || '');
    const hasStateInUrl = !!Object.keys(stateFromUrl).length;

    // State in url: Use that as state and overwrite the saved search cookie
    if (hasStateInUrl || forceMergeState) {
      if (mergeState === 'true' || forceMergeState) {
        initialSearchSelection.current = {
          ...stateFromCookie,
          ...stateFromUrl,
          filters: {
            ...stateFromCookie.filters,
            ...stateFromUrl.filters,
          },
        };
      } else {
        initialSearchSelection.current = stateFromUrl;
      }

      savedSearchSelection.set(
        createSearchSelectionStore(initialSearchSelection.current).getState().toCookie(),
      );

      // Otherwise restore search from cookie (falls back to empty state)
    } else {
      initialSearchSelection.current = stateFromCookie;
    }
  }

  return initialSearchSelection.current;
};
